<template>
  <v-col>
    <s-crud
      add
      add-no-function
      @add="addHistory()"
      @doubleClick="doubleClickItem($event)"
      title="Historias Médicas"
      :filter="filter"
      :config="config"
      ref="crudHistory"
      searchInput
    >
      <template v-slot:filter>
        <div>
          <v-col style="">
            <v-row justify="center">
              <v-col lg="3">
                <s-select-definition label="Según Ficha General" v-model="filter.MevStatus" :def="1046" clearable></s-select-definition>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </template>
      <template v-slot:MevStatus="{ row }">
        <div>
          <v-chip :dark="row.MevStatus == 9 ? false : true" x-small :color="getColorMevStatus(row.MevStatus)">
            {{ getNameStatus(row.MevStatus) }}
          </v-chip>
        </div>
      </template>
    </s-crud>

    <v-dialog style="no-scroll" v-model="dialog" v-if="dialog" persistent transition="dialog-bottom-transition">
      <v-card style="overflow: hidden !important;">
        <view-history-edit @close="closeDialog()" :pHistory="history"></view-history-edit>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import s_HistoryRecord from "../../../services/HumanResource/HistoryRecordService";
import ViewHistoryEdit from "../../../views/HumanResource/OccupationalHealth/HistoryEdit";
export default {
  name: "HistoryRecord",
  components: { ViewHistoryEdit },
  data() {
    return {
      history: null,
      dialog: false,
      filter: { MevStatus: 0, NtpID: 0 },
      itemPending: [
        {
          text: "NUEVO",
          value: "0",
          icon: "mdi-plus-circle-outline",
          color: "primary",
        },
      ],
      itemCreating: [
        { text: "VER", value: "0", icon: "mdi-eye" },
        {
          text: "EDITAR",
          value: "0",
          icon: "mdi-file-edit-outline",
          color: "warning",
        },
      ],
      itemByAprovved: [{ text: "VER", value: "0", icon: "mdi-eye" }],
      itemAprovved: [
        { text: "VER", value: "0", icon: "mdi-eye" },
        {
          text: "NUEVO",
          value: "0",
          icon: "mdi-plus-circle-outline",
          color: "primary",
        },
        { text: "IMPRIMIR", value: "0", icon: "mdi-printer" },
      ],
      itemObserved: [
        {
          text: "EDITAR",
          value: "0",
          icon: "mdi-file-edit-outline",
          color: "warning",
        },
      ],
      itemCanceled: [
        {
          text: "NUEVO",
          value: "0",
          icon: "mdi-plus-circle-outline",
          color: "primary",
        },
      ],
      filter: { MevStatus: 0, WkmStatus: 0, NtpID: 0 },
      config: {
        model: { NtpID: "ID", MevStatus: "", MevDate: "date" },
        headers: [
          { text: "ID", value: "NtpID" },
          { text: "Tipo", value: "TypePersonDocumentText" },
          { text: "Doc", value: "PrsDocumentNumber" },
          { text: "Nombre Completo", value: "NtpFullName" },
          { text: "Edad", value: "NtpAge" },
          { text: "Género", value: "TypeSexText" },

          { text: "Telefono", value: "NtpTelephone" },
          { text: "Fecha", value: "MevDate" },
          { text: "Estado", value: "MevStatus" },
        ],
        service: s_HistoryRecord,
      },
    };
  },
  computed: {},
  methods: {
    addHistory() {
      this.history = null;
      this.dialog = true;
    },
    doubleClickItem(row) {
      this.dialog = true;
      this.history = row;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.crudHistory.refresh();
    },
    itemsOptions(status) {
      if (status == 9) return this.itemPending;
      //pendiente
      else if (status == 3) return this.itemAprovved;
      // aprobado
      else if (status == 1) return this.itemCreating;
      // en creacion
      else if (status == 2) return this.itemByAprovved;
      //por aprobacion
      else if (status == 4) return this.itemCanceled;
      //anulado
      else if (status == 5) return this.itemObserved; //anulado
    },
    getColorMevStatus(status) {
      if (status == 9) return "white";
      //pendiente
      else if (status == 3) return "success";
      // aprobado
      else if (status == 1) return "info";
      // en creacion
      else if (status == 2) return "primary";
      //por aprobacion
      else if (status == 4) return "error";
      //anulado
      else if (status == 5) return "warning"; //anulado
    },
    getNameStatus(status) {
      if (status == 9) return "Pendiente";
      //pendiente
      else if (status == 3) return "Aprobado";
      // aprobado
      else if (status == 1) return "En Creación";
      // en creacion
      else if (status == 2) return "Por Abrobar";
      //por aprobacion
      else if (status == 4) return "Anulado";
      //anulado
      else if (status == 5) return "Observado"; //anulado
    },
  },

  created() {},
  watch: {},
};
</script>
