<template>
  <div>
    <s-toolbar
      label="Evaluación"
      :save="history.MevStatus == 1 || history.MevStatus == 9 || history.MevStatus == 5"
      :print="history.MevStatus == 3"
      @print="printPDF()"
      @exit="exitView()"
      exit
      :search="false"
      @save="save()"
    >
      <template v-slot:options>
        <v-tooltip bottom v-if="history.MevStatus == 2 && isAdmin">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="changeState(3)" elevation="0">
              <v-icon style="font-size:18px;">far fa-thumbs-up</v-icon>
            </v-btn>
          </template>
          <span>Aprobar</span></v-tooltip
        >

        <v-tooltip bottom v-if="history.MevStatus == 2 && isAdmin">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="changeState(5)" elevation="0">
              <v-icon style="font-size:18px; ">far fa-thumbs-down</v-icon>
            </v-btn>
          </template>
          <span>Observar</span></v-tooltip
        >
      </template>
    </s-toolbar>
    <v-card>
      <v-container>
        <v-row>
          <v-col class="s-col-form" style="padding-right:6px;" lg="2">
            <s-text v-if="pHistory != null" readonly label="Tipo" :value="history.TypePersonDocumentText"> </s-text>
            <s-select-definition v-else label="Tipo" :def="1036" v-model="history.TypePersonDocumentText"> </s-select-definition>
          </v-col>
          <v-col lg="2" class="s-col-form">
            <s-text v-if="pHistory != null" readonly label="Documento" :value="history.PrsDocumentNumber"></s-text>
            <s-toolbar-person ref="document" @returnPerson="returnPerson($event)" v-else addTypeSex addBirthDate v-model="person"></s-toolbar-person>
          </v-col>

          <v-col lg="6" class="s-col-form">
            <s-text readonly label="Apellidos y Nombres" v-model="history.NtpFullName"> </s-text>
          </v-col>
          <v-col lg="1" class="s-col-form">
            <s-text readonly label="Género" :value="history.TypeSexText"> </s-text>
          </v-col>
          <v-col lg="1" class="s-col-form">
            <s-text readonly label="Edad" :value="history.NtpAge">> </s-text>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col lg="3" class="s-col-form">
            <s-text readonly label="Fecha Registro" date :value="history.MevDate"> </s-text>
          </v-col>
          <v-col lg="2" class="s-col-form">
            <s-select hide-details dense label="Estado" :items="itemsMevStatus" v-model="MevStatus" :disabled="disabled" outlined></s-select>
          </v-col>
          <!--  <v-col lg=4 class="s-col-form">
                <s-text disabled readonly label="Aprobado por"> </s-text>
            </v-col>-->
          <v-col lg="2" class="s-col-form">
            <s-text readonly label="N° Ficha" v-model="history.MevDocumentNumber"> </s-text>
          </v-col>
        </v-row>
        <v-card elevation="0" style="margin-top:24px;">
          <v-divider></v-divider>
          <v-tabs height="30 " v-model="tab" color="primary" centered>
            <v-tab v-for="item in items" :key="item"> {{ item }} </v-tab>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-col lg="12">
                    <v-row>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Hijos Vivos" autofocus :disabled="disabled" number v-model="history.MevChildLiving"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Hijos Muertos" ref="dev" :disabled="disabled" number v-model="history.MevChildDead"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Abortos" :disabled="disabled" number v-model="history.MevChildAborted"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Dependientes" :disabled="disabled" number v-model="history.MevChildDepend"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Alergias" :disabled="disabled" v-model="history.MevAllergy"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="TBC" :disabled="disabled" v-model="history.MevTBC"></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Bronquitos Crónica" :disabled="disabled" v-model="history.MevBronchitis"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Convulsiones" :disabled="disabled" v-model="history.MevConvulsion"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Daibetes" :disabled="disabled" v-model="history.MevDiabetes"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="HTA" :disabled="disabled" v-model="history.MevHTA"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Neoplasia" :disabled="disabled" v-model="history.MevNeoplasia"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Asma" :disabled="disabled" v-model="history.MevAsthma"></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Hepatitis" :disabled="disabled" v-model="history.MevHepatitis"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="ETS" :disabled="disabled" v-model="history.MevETS"></s-text>
                      </v-col>
                      <v-col lg="8" class="s-col-form">
                        <s-text label="Otros" :disabled="disabled" v-model="history.MevOtherPathology"></s-text>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col lg="6" class="s-col-form">
                        <s-textarea
                          outlined
                          height="70"
                          :disabled="disabled"
                          v-model="history.MevFamilyPathology"
                          hide-details=""
                          label="Antecedentes Familiares"
                        ></s-textarea>
                      </v-col>
                      <v-col lg="6" class="s-col-form">
                        <s-textarea
                          outlined
                          height="70"
                          :readonly="disabled"
                          v-model="history.MevObservationPathology"
                          hide-details=""
                          label="Observaciones (Otras Enfermedades, Medicamentos que consume habitualmente, etc.)"
                        ></s-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card>
                  <v-col lg="12">
                    <v-row justify="center">
                      <v-col lg="4" class="s-col-form">
                        <s-select-definition :def="1047" clearable label="Alcohol" :disabled="disabled" v-model="history.TypeAlcohol">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Cantidad/Botella" autofocus :readonly="disabled" number v-model="history.MevAlcoholQuantity"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition :def="1049" :disabled="disabled" clearable label="Frecuencia" v-model="history.TypeAlcoholMeasure">
                        </s-select-definition>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col lg="4" class="s-col-form">
                        <s-select-definition :def="1048" :disabled="disabled" clearable label="Cigarro" v-model="history.TypeTobacco">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Cantidad" number :readonly="disabled" v-model="history.MevTobaccoQuantity"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition :def="1049" :disabled="disabled" clearable label="Frecuencia" v-model="history.TypeTobaccoMeasure">
                        </s-select-definition>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition clearable :disabled="disabled" :def="1050" label="Apetito" v-model="history.TypeLevelAppetite">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Valor" v-model="history.MevAppetite" :readonly="disabled"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition clearable :def="1050" :disabled="disabled" label="Sed" v-model="history.TypeLevelThirst">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Valor" v-model="history.MevThirst" :readonly="disabled"></s-text>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition clearable :disabled="disabled" :def="1050" label="Orina" v-model="history.TypeLevelUrine">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Valor" v-model="history.MevUrine" :readonly="disabled"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition clearable :disabled="disabled" :def="1050" label="Deposición" v-model="history.TypeLevelDepositions">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Valor" :readonly="disabled" v-model="history.MevDepositions"></s-text>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition clearable :disabled="disabled" :def="1050" label="Sueño" v-model="history.TypeLevelSleep">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Valor" v-model="history.MevSleep" :readonly="disabled"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-select-definition clearable :disabled="disabled" :def="1050" label="Peso" v-model="history.TypeLevelLostWeight">
                        </s-select-definition>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Valor" v-model="history.MevLostWeight" :readonly="disabled"></s-text>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card>
                  <v-col lg="12">
                    <v-row>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Frec. Cardiaca Lat*Min" autofocus :readonly="disabled" decimal v-model="history.MevHeartRate"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Frec. Respiratoria * Min" :readonly="disabled" decimal v-model="history.MevBreathingFrecuency"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Temperatura °C" decimal :readonly="disabled" v-model="history.MevTemperature"></s-text>
                      </v-col>
                      <v-col lg="1" class="s-col-form">
                        <s-text label="SaT O2(%)" decimal :readonly="disabled" v-model="history.MevOxygenSaturation"></s-text>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-text label="Presión Arterial(mm Hg)" :readonly="disabled" decimal v-model="history.MevBloodPressure"></s-text>
                      </v-col>
                      <v-col lg="1" class="s-col-form">
                        <s-text label="Talla (m)" decimal :readonly="disabled" v-model="history.MevSize"></s-text>
                      </v-col>
                      <v-col lg="1" class="s-col-form">
                        <s-text label="Peso (KG)" decimal :readonly="disabled" v-model="history.MevWeight"></s-text>
                      </v-col>
                      <v-col lg="1" class="s-col-form">
                        <s-text label="IMC P/T2" readonly decimal v-model="history.MevBMI" id="MevBMI"></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <s-text label="Ectoscopia" :disabled="disabled" v-model="history.MevEctoscopy"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Anamnesis" :disabled="disabled" v-model="history.MevAnamnesis"></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <s-text label="Agudeza visual lejos OD" :disabled="disabled" v-model="history.MevVisualFarRight"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Agudeza visual lejos OI" :disabled="disabled" v-model="history.MevVisualFarLeft"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Con correctores OD" :disabled="disabled" v-model="history.MevVisualFarRightLense"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Con correctores OI" :disabled="disabled" v-model="history.MevVisualFarLeftLense"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Agudeza visual cerca OD" :disabled="disabled" v-model="history.MevVisualNearRight"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Agudeza visual cerca OI" :disabled="disabled" v-model="history.MevVisualNearLeft"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Con correctores OD" :disabled="disabled" v-model="history.MevVisualNearRightLense"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Con correctores OI" :disabled="disabled" v-model="history.MevVisualNearLeftLense"></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <s-text label="Ojos y Anexos" :disabled="disabled" v-model="history.MevEyes"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Piel" v-model="history.MevSkin" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Cabello" v-model="history.MevHair" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Boca" v-model="history.MevMouth" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Oídos" v-model="history.MevEar" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Nariz" v-model="history.MevNose" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Cuello" v-model="history.MevNeck" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Aparato Respiratorio" v-model="history.MevRespiratory" :disabled="disabled"></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <s-text label="Aparato Circulatorio" v-model="history.MevCirculatory" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Aparato Genitourinario" v-model="history.MevUrinary" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Aparato Digestivo" v-model="history.MevDigestive" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Aparato Locomotor" v-model="history.MevLocomotor" :disabled="disabled"></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <s-text label="Columna" v-model="history.MevColumn" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Marcha" v-model="history.MevMarcha" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Miembros Superior" v-model="history.MevMemberUpper" :disabled="disabled"></s-text>
                      </v-col>
                      <v-col class="s-col-form">
                        <s-text label="Miembros Inferior" v-model="history.MevMemberLower" :disabled="disabled"></s-text>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
          <v-row>
            <v-col lg="12">
              <v-col lg="12">
                <s-textarea
                  outlined
                  height="70"
                  hide-details=""
                  :readonly="disabled"
                  v-model="history.MevDiagnostic"
                  label="Diagnostico"
                ></s-textarea>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import s_Numeration from "../../../services/General/NumerationDocumentService";
import s_HistoryRecord from "../../../services/HumanResource/HistoryRecordService";
import SToolbarPerson from "../../../components/Utils/Person/sToolbarPerson";
export default {
  name: "HistoryEdit",
  components: { SToolbarPerson },
  props: {
    pHistory: null,
  },
  data() {
    return {
      itemsMevStatus: [
        { text: "En Creación", value: 1 },
        { text: "Por Aprobación", value: 2 },
      ],
      person: null,
      MevStatus: 1,
      disabled: false,
      history: {
        MevDocumentNumber: "",
        MevStatus: 1,
        PrsDocumentNumber: "",
        TypePersonDocumentText: "",
        NtpFullName: null,

        NtpAge: "",
        MevDate: "",
        Telephone: "",

        MevChildLiving: 0,
        MevChildDead: 0,
        MevChildAborted: 0,
        MevChildDepend: 0,
        MevAllergy: "",
        MevTBC: "",
        MevBronchitis: "",
        MevConvulsion: "",
        MevDiabetes: "",
        MevHTA: "",
        MevNeoplasia: "",
        MevAsthma: "",
        MevHepatitis: "",
        MevETS: "",
        MevOtherPathology: "",

        TypeAlcohol: 0,
        MevAlcoholQuantity: 0,
        TypeAlcoholMeasure: "",
        TypeTobacco: 0,
        MevTobaccoQuantity: 0,
        TypeTobaccoMeasure: "",
        TypeLevelAppetite: 0,
        MevAppetite: "",
        TypeLevelThirst: 0,
        MevThirst: "",
        TypeLevelUrine: 0,
        MevUrine: "",
        TypeLevelDepositions: 0,
        MevDepositions: "",
        TypeLevelSleep: 0,
        MevSleep: "",
        TypeLevelLostWeight: 0,
        MevLostWeight: "",

        MevHeartRate: 0,
        MevBreathingFrecuency: 0,
        MevTemperature: 0,
        MevOxygenSaturation: 0,
        MevBloodPressure: 0,
        MevSize: 0,
        MevWeight: 0,
        MevBMI: 0,
        MevEctoscopy: "",
        MevAnamnesis: "",
        MevVisualFarRight: "",
        MevVisualFarLeft: "",
        MevVisualFarRightLense: "",
        MevVisualFarLeftLense: "",
        MevVisualNearRight: "",
        MevVisualNearLeft: "",
        MevEyes: "",
        MevSkin: "",
        MevHair: "",
        MevMouth: "",
        MevEar: "",
        MevNose: "",
        MevNeck: "",
        MevRespiratory: "",
        MevCirculatory: "",
        MevUrinary: "",
        MevDigestive: "",
        MevLocomotor: "",
        MevColumn: "",
        MevMarcha: "",
        MevMemberUpper: "",
        MevMemberLower: "",

        MevDiagnostic: "",
      },

      //75704710

      tab: null,
      items: ["ANTECEDENTES PATOLÓGICOS", "HÁBITOS NOCIVOS Y FUNCIONES BIOLÓGICAS", "REVISIÓN MÉDICA"],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  computed: {
    isAdmin() {
      return this.$fun.getSecurity().IsLevelAdmin;
    },
  },
  methods: {
    returnPerson(person) {
      console.log(person);
      if (person != null) {
        this.history.NtpID = person.NtpID;
        this.history.MevPersonPaternalSurname = person.NtpPaternalSurname;
        this.history.MevPersonMaternalSurname = person.NtpMaternalSurname;
        this.history.MevPersonName = person.NtpName;

        this.history.TypePersonDocument = person.TypePersonDocument;
        this.history.MevPersonDocumentNumber = person.PrsDocumentNumber;
        this.history.PrsDocumentNumber = person.PrsDocumentNumber;

        this.history.NtpFullName = person.NtpFullName;
        this.history.TypeSexText = person.TypeSexText;
        this.history.NtpBirthDate = person.NtpBirthDate;
        this.history.PrsDocumentNumber = person.PrsDocumentNumber;
      } else {
        this.history.NtpFullName = null;
        this.history.TypeSexText = null;
      }
    },
    printPDF() {
      s_HistoryRecord.pdf(this.pHistory).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "Evaluación Médica");
      });
    },
    exitView() {
      if (this.history.MevStatus == 3) {
        this.$emit("close");
        return;
      }
      this.$fun.alert("Seguro de salir sin guardar Cambios?", "question").then((r) => {
        if (r.value) {
          this.$emit("close");
        }
      });
    },
    changeState(status) {
      let msg = "Seguro de Aprobar Evaluación";
      this.history.MevMedicApprovalDate = this.$moment().format("YYYY-MM-DD");
      if (status == 5) {
        msg = "Seguro de Observar Evaluación";
        this.history.MevMedicApprovalDate = null;
      }

      this.history.UsrMedicID = this.$fun.getUserID();
      this.$fun.alert(msg, "question").then((r) => {
        if (r.value) {
          this.history.MevStatus = status;
          s_HistoryRecord.save(this.history, this.$fun.getUserID()).then((r) => {
            this.$fun.alert("Guardado Corectamente", "success");
            this.$emit("close");
          });
        }
      });
    },
    save() {
      //VALIDACIONES SI ES CREACION POR NUEVO
      if (this.pHistory == null)
        if (this.history.NtpFullName == null) {
          this.$fun.alert("Persona no Válida", "warning");
          return;
        }

      if (this.history.MevSize > 0) this.history.MevBMI = this.history.MevWeight / (this.history.MevSize * this.history.MevSize);
      else this.history.MevBMI = 0;

      this.history.TypeDocument = 2;
      this.$fun.alert("Seguro de guardar Datos", "question").then((r) => {
        if (r.value) {
          this.history.MevStatus = this.MevStatus;
          s_HistoryRecord.save(this.history, this.$fun.getUserID()).then((r) => {
            this.$fun.alert("Guardado Corectamente", "success");
            this.$emit("close");
          });
        }
      });
    },
  },
  mounted() {
    if (this.pHistory == null) this.$refs.document.focus();
  },
  created() {
    if (this.pHistory != null) {
      if (this.pHistory.MevStatus == 2 || this.pHistory.MevStatus == 3) this.disabled = true;
      if (this.pHistory.MevStatus != 9 && this.pHistory.MevStatus != 5) this.MevStatus = this.pHistory.MevStatus;
      if (this.pHistory) Object.assign(this.history, this.pHistory);
      this.history.TypeDocument = this.history.TypePersonDocumentText;

      if (this.pHistory.MevID == null) {
        this.history.MevDate = this.$moment().format("L");
        s_Numeration.current(2).then((r) => {
          this.history.MevDocumentNumber = r.data.NudDocumentNumber;
        });
      }
    } else {
      this.history.MevDate = this.$moment().format("L");
      s_Numeration.current(2).then((r) => {
        this.history.MevDocumentNumber = r.data.NudDocumentNumber;
      });
    }
  },
  watch: {
    "history.MevSize"() {
      if (this.history.MevSize > 0) this.history.MevBMI = this.history.MevWeight / (this.history.MevSize * this.history.MevSize);
    },
    "history.MevWeight"() {
      if (this.history.MevSize > 0) this.history.MevBMI = this.history.MevWeight / (this.history.MevSize * this.history.MevSize);
    },
    person() {},
    pHistory() {
      if (this.pHistory) this.history.NtpFullName = this.pHistory.NtpFullName;
    },
  },
};
</script>

<style>
#MevBMI {
  font-color: green;
}
</style>
