import Service from "../Service";

const resource = "sfw/medicalhistory/";

export default {
  pdf(obj, requestID) {
    return Service.post(resource + "pdfHistory",obj, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
        //  params: { requestID: requestID },
        });
      },
      save(obj, requestID) {
        return Service.post(resource + "save", obj, {
        params: { requestID: requestID },
        });
      },

  

};